@import "https://fonts.googleapis.com/css2?family=Bodoni+Moda&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap";
:root {
  --dark-teal: #004040;
  --paper-padding: min(8ch, 8vw);
  --paper-width: min(80ch, 90vw);
  --paper-space-around: calc((100vw - var(--paper-width)) / 2);
  --photo-offset: max(calc(-2 * var(--paper-padding)), calc(-2 * var(--paper-space-around)));
}

html {
  scroll-behavior: smooth;
  padding-block-end: var(--paper-padding);
  overflow-x: hidden;
}

@media screen {
  html {
    background: linear-gradient(170deg, var(--dark-teal), #0008 100vh) fixed, url("flowers.7d3ed94e.webp") fixed 50% 0 / cover;
    background-color: var(--dark-teal);
    color: #fff;
  }
}

body {
  margin: 0;
}

header {
  font-family: Bodoni Moda, Didot, serif;
  font-size: 1.4rem;
}

@media screen {
  header {
    text-align: center;
    text-shadow: -1px 1px #000, -2px 2px 1px #000;
    margin-block-start: 4vw;
  }
}

h1 {
  font-size: 4rem;
  font-weight: inherit;
  margin: 0;
  line-height: 1;
}

hr {
  border: 0;
  border-block-start: 1px solid #8884;
  margin-block: 2em;
}

a:any-link {
  color: teal;
  word-break: break-word;
}

button:not(:disabled) {
  cursor: pointer;
}

@media screen and (width >= 600px) {
  :is(.sheet-of-paper, .photo) {
    rotate: calc(var(--tilt) * 1deg);
    transition: rotate .2s;
  }
}

@media screen {
  .stack-of-papers {
    width: fit-content;
    max-width: 95%;
    margin-inline: auto;
  }
}

@media print {
  .sentinel {
    display: none;
  }
}

.sheet-of-paper {
  break-inside: avoid;
  font: 1rem / 1.4 Courier Prime, Courier, monospace;
}

@media screen {
  .sheet-of-paper {
    aspect-ratio: 8.5 / 11;
    box-sizing: border-box;
    color: #000;
    margin-block: calc(var(--paper-padding) / 2);
    padding: var(--paper-padding);
    width: var(--paper-width);
    background: #eee;
    box-shadow: 0 10px 20px #0004;
  }

  @media (width >= 600px) {
    .sheet-of-paper:nth-of-type(odd) {
      margin-inline-start: -1vw;
    }

    .sheet-of-paper:nth-of-type(2n) {
      margin-inline-start: 1vw;
    }
  }

  .sentinel + .sheet-of-paper {
    position: fixed;
  }

  .sheet-of-paper > :first-child {
    margin-block-start: 0;
  }

  .sheet-of-paper > :last-child {
    margin-block-end: 0;
  }
}

.photo {
  background-color: #fff;
  border: 1px solid #0006;
  border-radius: 3px;
  margin: 0 1em 1em;
}

@media screen {
  .photo {
    --photo-padding: calc(var(--paper-padding) / 6);
    padding: var(--photo-padding) var(--photo-padding) calc(var(--photo-padding) * 1.2);
    width: calc(var(--paper-width) / 4);
    box-shadow: 0 5px 10px #0003;
  }
}

@media print {
  .photo {
    width: calc(var(--paper-width) / 5);
  }
}

.photo.big {
  width: calc(var(--paper-width) / 2.5);
}

@media print {
  .photo.big {
    width: calc(var(--paper-width) / 3.5);
  }
}

.photo.start {
  float: left;
}

@media screen {
  .photo.start {
    margin-left: var(--photo-offset);
  }
}

.photo.end {
  float: right;
}

@media screen {
  .photo.end {
    margin-right: var(--photo-offset);
  }
}

dl > div {
  break-inside: avoid;
}

dt {
  font-weight: bold;
}

dt:not(:first-child > *) {
  border-block-start: 1px solid #8884;
  margin-block-start: 1em;
  padding-block-start: 1em;
}

dd {
  margin-inline-start: max(2vw, 1rem);
}

.comps {
  color: teal;
  font-size: small;
}

ab-gallery:not(:defined) {
  display: none;
}

@property --start-fade {
  syntax: "<length>";
  inherits: false;
  initial-value: 0;
}

@property --end-fade {
  syntax: "<length>";
  inherits: false;
  initial-value: 0;
}
/*# sourceMappingURL=index.b2e00ed6.css.map */
