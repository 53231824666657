@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap");

:root {
	--dark-teal: color-mix(in srgb, teal, black 50%);
	--paper-padding: min(8ch, 8vw);
	--paper-width: min(80ch, 90vw);
	--paper-space-around: calc((100vw - var(--paper-width)) / 2);
	--photo-offset: max(
			calc( -2 * var(--paper-padding)),
			calc(-2 * var(--paper-space-around))
		);
}

html {
	overflow-x: hidden;
	padding-block-end: var(--paper-padding);
	scroll-behavior: smooth;

	@media screen {
		background:
			linear-gradient(170deg, var(--dark-teal), #0008 100vh) fixed,
			url("./images/flowers.jpg?as=webp&width=1024") fixed 50% 0 / cover;
		background-color: var(--dark-teal);
		color: white;
	}
}

body {
	margin: 0;
}

header {
	font-family: "Bodoni Moda", "Didot", serif;
	font-size: 1.4rem;

	@media screen {
		margin-block-start: 4vw;
		text-align: center;
		text-shadow:
			-1px 1px 0 black,
			-2px 2px 1px black;
	}
}

h1 {
	font-size: 4rem;
	font-weight: inherit;
	line-height: 1;
	margin: 0;
}

hr {
	border: 0;
	border-block-start: 1px solid #8884;
	margin-block: 2em;
}

a:any-link {
	color: teal;
	word-break: break-word;
}

button:not(:disabled) {
	cursor: pointer;
}

.sheet-of-paper,
.photo {
	@media screen and (min-width: 600px) {
		rotate: calc(var(--tilt) * 1deg);
		transition: rotate 0.2s;
	}
}

.stack-of-papers {
	@media screen {
		margin-inline: auto;
		max-width: 95%;
		width: fit-content;
	}
}

.sentinel {
	@media print {
		display: none;
	}
}

.sheet-of-paper {
	break-inside: avoid;
	font: 1rem / 1.4 "Courier Prime", "Courier", monospace;

	@media screen {
		aspect-ratio: 8.5 / 11;
		background: #eee;
		box-shadow: 0 10px 20px #0004;
		box-sizing: border-box;
		color: black;
		margin-block: calc(var(--paper-padding) / 2);
		padding: var(--paper-padding);
		width: var(--paper-width);

		@media (min-width: 600px) {
			&:nth-of-type(odd) {
				margin-inline-start: -1vw;
			}

			&:nth-of-type(even) {
				margin-inline-start: 1vw;
			}
		}

		.sentinel + & {
			position: fixed;
		}

		> :first-child {
			margin-block-start: 0;
		}

		> :last-child {
			margin-block-end: 0;
		}
	}
}

.photo {
	background-color: white;
	border: 1px solid #0006;
	border-radius: 3px;
	margin: 0 1em 1em;

	@media screen {
		--photo-padding: calc(var(--paper-padding) / 6);
		box-shadow: 0 5px 10px #0003;
		padding: var(--photo-padding) var(--photo-padding) calc(var(--photo-padding) * 1.2);
		width: calc(var(--paper-width) / 4);
	}

	@media print {
		width: calc(var(--paper-width) / 5);
	}

	&.big {
		width: calc(var(--paper-width) / 2.5);

		@media print {
			width: calc(var(--paper-width) / 3.5);
		}
	}

	&.start {
		float: left;

		@media screen {
			margin-left: var(--photo-offset);
		}
	}

	&.end {
		float: right;

		@media screen {
			margin-right: var(--photo-offset);
		}
	}
}

dl > div {
	break-inside: avoid;
}

dt {
	font-weight: bold;
}

dt:not(:first-child > *) {
	border-block-start: 1px solid #8884;
	margin-block-start: 1em;
	padding-block-start: 1em;
}

dd {
	margin-inline-start: max(2vw, 1rem);
}

.comps {
	color: teal;
	font-size: small;
}

ab-gallery:not(:defined) {
	display: none;
}

/* Custom properties have to be defined globally, I guess: */

@property --start-fade {
	inherits: false;
	initial-value: 0;
	syntax: '<length>';
}

@property --end-fade {
	inherits: false;
	initial-value: 0;
	syntax: '<length>';
}
